<template>
  <modal name="share_matrixx_chart_modal" @before-open="beforeOpen" height="auto" width="1200px">
    <div class="modal_container modal_container--matrixx_chart_new" v-if="matrixxChart">
      <div class="modal_header">
        <h1 class="title">Download Matrix Chart Asset</h1>
        <div class="closer" @click="$modal.hide('share_matrixx_chart_modal')">
          <TimesIcon></TimesIcon>
        </div>
      </div>

      <div class="modal_body d-flex pt-4">
        <section class="sharing_options d-flex flex-column">
          <div class="settings pb-4">
            <p class="header">Settings</p>
          </div>

          <div class="visual_theme_options pb-2">
            <p class="header pb-1">Visual Theme</p>
            <section>
              <b-form-group>
                <b-form-select v-model="design" :options="visualThemes"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="stat_color_options pb-2">
            <p class="header pb-1">Infographic Color</p>
            <section>
              <b-form-group>
                <b-form-select v-model="statColor" :options="basicOptions"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="background_color pb-2" v-if="design == 'branded'">
            <p class="header pb-1">Background Color</p>
            <section>
              <b-form-group>
                <b-form-select
                  v-model="backgroundColor"
                  :options="backgroundColorOptions"
                ></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="file_type_options pb-2">
            <p class="header pb-2">File Type</p>
            <b-form-group>
              <b-form-radio name="filetype-radios" v-model="filetype" value="png">PNG</b-form-radio>
              <b-form-radio name="filetype-radios" v-model="filetype" class="mt-2" value="pdf"
                >PDF</b-form-radio
              >
            </b-form-group>
          </div>
        </section>

        <section v-if="matrixxChart" class="matrixx_chart_preview mr-0 pl-4">
          <MatrixxChartBasic
            :matrixxChart="matrixxChart"
            :account="account"
            :statColorOption="statColor"
            v-if="design == 'basic'"
          >
          </MatrixxChartBasic>

          <MatrixxChartBranded
            :matrixxChart="matrixxChart"
            :account="account"
            :statColorOption="statColor"
            :backgroundColorOption="backgroundColor"
            v-else
          />
        </section>
      </div>

      <div class="modal_footer_new d-flex justify-content-between">
        <CopyEmbedCode class="mr-3" v-if="matrixxChart.public" :snippet="embedSnippet" />
        <CopyShareLink
          v-if="matrixxChart.public"
          :ueId="matrixxChart.identifier"
          @copied="trackCopy"
        />
        <ButtonNew
          @click="onDownload"
          size="large"
          class="download_button mr-0 ml-auto"
          v-if="!download_in_progress"
        >
          <i class="bi bi-download" />
          Download Asset
        </ButtonNew>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import TimesIcon from './graphics/TimesIcon.vue'
import CopyEmbedCode from '@components/CopyEmbedCode.vue'
import CopyShareLink from '@components/CopyShareLink.vue'
import ButtonNew from '@components/new_components/ButtonNew.vue'
import MatrixxChartBasic from '@components/MatrixxChartBasic.vue'
import MatrixxChartBranded from '@components/MatrixxChartBranded.vue'
import { AxiosResponse } from 'axios'
import { downloadMatrixxChart } from '../app/api.js'
import ahoy from 'ahoy.js'

export default {
  components: {
    TimesIcon,
    CopyEmbedCode,
    CopyShareLink,
    ButtonNew,
    MatrixxChartBasic,
    MatrixxChartBranded,
  },
  data() {
    return {
      filetype: 'png',
      download_in_progress: false,
      matrixxChart: null,
      account: null,
      design: 'basic',
      statColor: 'primary',
      backgroundColor: 'primary',
      visualThemes: [
        {
          value: 'basic',
          text: 'Basic',
        },
        {
          value: 'branded',
          text: 'Branded',
        },
      ],
      basicOptions: [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
      ],
      backgroundColorOptions: [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
        {
          value: 'both',
          text: 'Primary and Secondary',
        },
        {
          value: 'dark',
          text: 'Dark',
        },
      ],
    }
  },
  computed: {
    download_options() {
      return {
        filetype: this.filetype,
        // We use `design` here to keep it consistent
        // with the Render components and AssetPresenter
        design: this.design,
        stat_color_option: this.statColor,
        background_color_option: this.backgroundColor,
      }
    },
    embedSnippet() {
      const srcUrl =
        location.protocol +
        '//' +
        location.host +
        '/render/matrixx_chart/' +
        this.matrixxChart.identifier
      return `<iframe src='${srcUrl}' frameBorder='0'></iframe>`
    },
    eventData() {
      return {
        asset_id: this.matrixxChart.identifier,
        asset_type: 'MatrixxChart',
      }
    },
  },
  methods: {
    beforeOpen(e) {
      this.matrixxChart = e.params.matrixx_chart
      this.account = this.matrixxChart.account
    },
    attemptPopUp(url: string) {
      const new_window = window.open(url, '_blank')
      try {
        new_window.focus()
      } catch (e) {
        // Trying to `window.open` a PDF attachment file seems to trigger pop-up blockers on Chrome
        alert(
          'It looks like a pop-up blocker prevented the download window from opening. Please allow them for this site.',
        )
      }
    },
    onDownload() {
      this.download_in_progress = true
      downloadMatrixxChart(this.matrixxChart.id, this.download_options)
        .then((r: AxiosResponse) => {
          return r.data.download_url
        })
        .then((url: string) => this.attemptPopUp(url))
        .catch(() => alert('Darn! Something went wrong. Please try again.'))
        .finally(() => (this.download_in_progress = false))
    },
    trackCopy() {
      ahoy.track('asset share link copied', this.eventData)
    },
  },
}
</script>
<style lang="sass" scoped>
.modal_container--matrixx_chart_new
  font-size: 16px
  .download_button
    border-radius: 8px
  &.modal_container .modal_header
    padding-bottom: .75em
  &.modal_container .matrixx_chart_preview
    width: 60%
    height: 100%
  .title
    font-size: 28px
    font-weight: 700
    margin-bottom: 0
  .sharing_options
    width: 40%
    gap: 10px
    .settings .header
      font-size: 20px
    .header
      font-size: 16px
      font-weight: 700
.modal_container--matrixx_chart_new
  &.modal_container .modal_footer_new
    border-top: 1px solid #CED4DA
    padding: 1em 1.5em 1em 1.5em
    position: relative
</style>
