<template>
  <FileAsset v-if="asset.type == 'FileAsset'" :file-asset="asset" />
  <VideoLinkAsset v-else-if="asset.type == 'VideoLinkAsset'" :video-link-asset="asset" />
  <TestimonialBasic
    v-else-if="asset.type == 'RenderableTestimonial'"
    :renderable-testimonial="asset"
    :account="account"
  />
  <BarChartBasic v-else-if="asset.type == 'ChartAsset'" :content-asset="asset" :account="account" />
  <StatBasic v-else-if="asset.type == 'StatAsset'" :content-asset="asset" />
  <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset" />
  <MatrixxChartBasic
    v-else-if="asset.type == 'MatrixxChart'"
    :account="account"
    :matrixxChart="asset"
  />
  <SpotlightPreview
    v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
    :content_asset="asset"
  />
</template>

<script>
import FileAsset from '@components/FileAsset.vue'
import VideoLinkAsset from '@components/VideoLinkAsset.vue'
import TestimonialBasic from '@components/TestimonialBasic.vue'
import BarChartBasic from '@components/BarChartBasic.vue'
import StatBasic from '@components/StatBasic.vue'
import Microsite from '@app/components/Microsite.vue'
import SpotlightPreview from '@components/SpotlightPreview.vue'
import MatrixxChartBasic from '@components/MatrixxChartBasic.vue'

export default {
  components: {
    FileAsset,
    VideoLinkAsset,
    TestimonialBasic,
    BarChartBasic,
    StatBasic,
    Microsite,
    SpotlightPreview,
    MatrixxChartBasic,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.reordered
  border: 1px solid red
</style>
