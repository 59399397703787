<template>
  <modal
    name="share_asset_modal_new"
    @before-open="beforeOpen"
    :adaptive="true"
    height="auto"
    width="1200px"
  >
    <div class="modal_container" v-if="contentAsset">
      <div class="modal_header">
        <h2 class="title mt-0 mb-0">Download {{ this.assetTypeText() }} Asset</h2>
        <div class="closer" @click="$modal.hide('share_asset_modal_new')">
          <TimesIcon></TimesIcon>
        </div>
      </div>

      <div class="modal_body d-flex pt-4">
        <section class="sharing_options d-flex flex-column">
          <div class="settings pb-4">
            <p class="header">Settings</p>
          </div>

          <div class="file_type_options pb-2">
            <p class="header pb-2">File Type</p>
            <b-form-group
              :description="showSlideNudge ? 'Contact us to setup PowerPoint slides' : null"
            >
              <b-form-radio name="filetype-radios" v-model="filetype" value="png">PNG</b-form-radio>
              <b-form-radio name="filetype-radios" v-model="filetype" class="mt-2" value="pdf">
                PDF
              </b-form-radio>
              <b-form-radio
                v-if="isTestimonial & showSlideOption"
                name="filetype-radios"
                v-model="filetype"
                class="mt-2"
                value="pptx"
                :disabled="showSlideNudge"
                >PPTX (PowerPoint) <span class="badge badge-primary">NEW</span></b-form-radio
              >
            </b-form-group>
          </div>

          <div v-if="showVisualThemeSettings" class="visual_theme_options pb-2">
            <p class="header pb-1">Visual Theme</p>
            <section>
              <b-form-group>
                <b-form-select v-model="design" :options="visualThemeOptions"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="stat_color_options pb-2" v-if="!isTestimonial">
            <p class="header pb-1">Infographic Color</p>
            <section>
              <b-form-group>
                <b-form-select v-model="statColor" :options="basicOptions"></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="background_color pb-2" v-if="design == 'branded'">
            <p class="header pb-1">Background Color</p>
            <section>
              <b-form-group>
                <b-form-select
                  v-model="backgroundColor"
                  :options="backgroundColorOptions"
                ></b-form-select>
              </b-form-group>
            </section>
          </div>

          <div class="size_options pb-2" v-if="showSizeSettings">
            <p class="header pb-2">Size</p>
            <b-form-group>
              <b-form-radio
                v-if="filetype !== 'pdf'"
                name="size-radios"
                v-model="size"
                value="flex"
              >
                Flexible<br />
                <small class="font-italic">Optimal for LinkedIn, Facebook</small>
              </b-form-radio>
              <b-form-radio name="size-radios" v-model="size" value="wide" class="mt-2"
                >Wide (16:9)<br />
                <small class="font-italic">Optimal for X aka Twitter</small>
              </b-form-radio>
              <b-form-radio name="size-radios" v-model="size" value="square" class="mt-2"
                >Square (1:1)<br />
                <small class="font-italic">Optimal for Instagram</small>
              </b-form-radio>
            </b-form-group>
          </div>
        </section>

        <div class="asset_preview mr-0 pl-4">
          <section v-if="contentAsset.type == 'StatAsset'">
            <StatBasic
              :contentAsset="contentAsset"
              :statColorOption="statColor"
              v-if="design == 'basic'"
            />

            <StatBranded
              :contentAsset="contentAsset"
              :statColorOption="statColor"
              :backgroundColorOption="backgroundColor"
              v-if="design == 'branded'"
            />
          </section>

          <section v-if="contentAsset.type == 'ChartAsset'">
            <BarChartBasic
              :contentAsset="contentAsset"
              :chartColorOption="statColor"
              :account="contentAsset.account"
              v-if="design == 'basic'"
            />

            <BarChartBranded
              :contentAsset="contentAsset"
              :chartColorOption="statColor"
              :backgroundColorOption="backgroundColor"
              v-if="design == 'branded'"
            />
          </section>

          <section v-if="contentAsset.type == 'MatrixxChart'">
            <!-- @TODO: Move ShareMatrixxChartModal here -->
          </section>

          <section v-if="contentAsset.type == 'RenderableTestimonial'">
            <AssetPresenter
              v-if="filetype !== 'pptx'"
              :content_asset="contentAsset"
              :design="testimonialDesignProp"
              :shape="size"
              :account="contentAsset.account"
              :theme="themeData"
            />
            <div
              :class="{ 'no-slide-preview-placeholder': true, preview_slide: slidePreviewUrl }"
              v-if="filetype === 'pptx'"
            >
              <img :src="slidePreviewUrl" v-if="slidePreviewUrl" />
              <span v-else>No preview available</span>
            </div>
          </section>
        </div>
      </div>

      <div class="modal_footer_new d-flex justify-content-between py-3 px-4">
        <CopyEmbedCode
          v-if="contentAsset.published || contentAsset.public"
          :snippet="this.embedSnippet()"
          class="mr-3"
        />
        <CopyShareLink
          v-if="contentAsset.published || contentAsset.public"
          :ueId="contentAsset.identifier"
          @copied="trackCopy"
        />

        <button @click="onDownload" class="btn btn-primary mr-0 ml-auto" v-if="!downloadInProgress">
          <i class="bi bi-download" />
          Download Asset
        </button>
      </div>
    </div>
  </modal>
</template>
<script lang="ts">
import AssetPresenter from '@survey/components/AssetPresenter.vue'
import TimesIcon from './graphics/TimesIcon.vue'
import CopyEmbedCode from '@components/CopyEmbedCode.vue'
import CopyShareLink from '@components/CopyShareLink.vue'
import StatBasic from '@components/StatBasic.vue'
import BarChartBasic from '@components/BarChartBasic.vue'
import StatBranded from '@components/StatBranded.vue'
import BarChartBranded from '@components/BarChartBranded.vue'
import { mapState } from 'pinia'
import { useUserStore } from '@app/stores/UserStore'
import { pendoTrack } from '@lib/pendo'
import { amplitudeTrack } from '@lib/amplitude'
import { downloadContentAsset, renderAsset } from '@app/api.js'
import { AxiosResponse } from 'axios'
import ahoy from 'ahoy.js'
import { assetEventData } from '@lib/tracking'
import { getTestimonialThemesMap } from '@app/api.js'

export default {
  components: {
    AssetPresenter,
    TimesIcon,
    CopyEmbedCode,
    CopyShareLink,
    StatBranded,
    StatBasic,
    BarChartBasic,
    BarChartBranded,
  },
  data() {
    return {
      testtimonialsIds: '',
      filetype: 'png',
      downloadInProgress: false,
      matrixxChart: null,
      account: null,
      design: 'basic',
      statColor: 'primary',
      backgroundColor: 'primary',
      size: 'flex',
      basicOptions: [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
      ],
      contentAsset: null,
      testimonialCustomThemes: [],
    }
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    isTestimonial() {
      return this.contentAsset.type === 'RenderableTestimonial'
    },
    showVisualThemeSettings() {
      return this.filetype !== 'pptx'
    },
    // The size setting only applies to testimonials.
    // The size setting is not available for PPTX downloads because size is dictated by the template.
    // BrandedTestimonial (only) does not support the shape prop. 🤔
    showSizeSettings() {
      return this.isTestimonial && this.filetype !== 'pptx' && this.design !== 'branded'
    },
    showSlideOption() {
      return this.slideFeatureIsEnabled
    },
    showSlideNudge() {
      // Slide feature is available BUT the account has no templates installed
      return this.slideFeatureIsEnabled && !this.slideTemplatesAreAvailable
    },
    slideFeatureIsEnabled() {
      return this.account?.flipper_features.includes('slides')
    },
    slideTemplatesAreAvailable() {
      const slide_templates = this.account?.slide_templates
      return slide_templates && slide_templates.length > 0 && slide_templates[0]?.slide_url
    },
    slidePreviewUrl() {
      return this.account?.slide_templates[0]?.preview_url
    },
    themeData() {
      const current_style = this.design
      if (!current_style.match(/custom/)) {
        return null
      }
      const active_id = current_style.split(':')[1]
      return this.testimonialCustomThemes[active_id].data
    },
    // The API for testimonials is a bit different
    // The Branded version has an additional backgroundColorOption
    // prop that is derived from the design prop.
    // For example, a valid value for `design` is 'branded_primary'
    // which is actually communicating two things:
    // 1. The design is 'branded'
    // 2. The background color is 'primary'
    // This ☝️ kookiness is what we are reverse engineering in the
    // following computed property.
    testimonialDesignProp() {
      if (this.design !== 'branded') {
        return this.design
      }
      switch (this.backgroundColor) {
        case 'primary':
          return 'branded_primary'
        case 'secondary':
          return 'branded_secondary'
        case 'both':
          return 'branded_primary_and_secondary'
        case 'dark':
          return 'branded_dark'
        default:
          return 'branded_primary'
      }
    },
    visualThemeOptions() {
      const sharedThemes = [
        {
          value: 'basic',
          text: 'Basic',
        },
        {
          value: 'branded',
          text: 'Branded',
        },
      ]
      const testimonialThemes = [
        {
          value: 'styled',
          text: 'Accent',
        },
      ]
      if (this.isTestimonial) {
        const customThemeOptions = []
        Object.keys(this.testimonialCustomThemes).forEach((key) => {
          const theme = this.testimonialCustomThemes[key]
          customThemeOptions.push({
            value: `custom:${theme.id}`,
            text: theme.name,
          })
        })
        return sharedThemes.concat(testimonialThemes, customThemeOptions)
      } else {
        return sharedThemes
      }
    },
    backgroundColorOptions() {
      const sharedOptions = [
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
        {
          value: 'both',
          text: 'Primary and Secondary',
        },
      ]
      const notTestimonialOptions = [
        {
          value: 'dark',
          text: 'Dark',
        },
      ]
      if (this.isTestimonial) {
        return sharedOptions
      } else {
        return sharedOptions.concat(notTestimonialOptions)
      }
    },
    downloadOptions() {
      return {
        filetype: this.filetype,
        // We use `design` here to keep it consistent
        // with the Render components and AssetPresenter
        design: this.design,
        stat_color_option: this.statColor,
        background_color_option: this.backgroundColor,
      }
    },
    testimonialDownloadOptions() {
      let theme_type = this.design
      if (theme_type.match(/custom/)) {
        theme_type = 'custom'
      }
      if (theme_type === 'branded') {
        switch (this.backgroundColor) {
          case 'primary':
            theme_type = 'branded_primary'
            break
          case 'secondary':
            theme_type = 'branded_secondary'
            break
          case 'both':
            theme_type = 'branded_primary_and_secondary'
            break
          default:
            theme_type = 'branded_primary'
        }
      }
      return {
        shape: this.size,
        filetype: this.filetype,
        // theme type is the design value or 'custom' and theme_id is the id of the theme
        theme_type: theme_type,
        theme_id: this.design.match(/custom/) ? this.design.split(':')[1] : null,
      }
    },
    eventData() {
      return {
        ...assetEventData(this.contentAsset),
        filetype: this.filetype,
      }
    },
  },
  methods: {
    // @TODO: this function is a duplicate of the one in ShareMatrixxChartModal.vue
    // move this to a library function
    attemptPopUp(url: string) {
      const new_window = window.open(url, '_blank')
      try {
        new_window.focus()
      } catch (e) {
        // Trying to `window.open` a PDF attachment file seems to trigger pop-up blockers on Chrome
        alert(
          'It looks like a pop-up blocker prevented the download window from opening. Please allow them for this site.',
        )
      }
    },
    assetTypeText() {
      switch (this.contentAsset.type) {
        case 'ChartAsset':
          return 'Chart'
        case 'StatAsset':
          return 'Stat'
        case 'RenderableTestimonial':
          return 'Testimonial'
        case 'CustomerSpotlight':
          return 'Customer Spotlight'
        case 'SurveySpotlightAsset':
          return 'Survey Spotlight'
        default:
          return null
      }
    },
    assetTypeEmbedName() {
      switch (this.contentAsset.type) {
        case 'ChartAsset':
          return 'chart_asset'
        case 'StatAsset':
          return 'stat_asset'
        case 'RenderableTestimonial':
          return 'testimonial'
        default:
          return null
      }
    },
    embedSnippet() {
      const srcUrl =
        location.protocol +
        '//' +
        location.host +
        `/render/${this.assetTypeEmbedName()}/` +
        `${this.contentAsset.identifier}?` +
        new URLSearchParams(this.downloadOptions).toString()
      return `<iframe src='${srcUrl}' frameBorder='0'></iframe>`
    },
    async beforeOpen(e) {
      this.contentAsset = e.params.content_asset
      this.account = this.contentAsset.account
      // only fetch themes if the asset is a testimonial
      if (this.contentAsset.type === 'RenderableTestimonial') {
        this.testimonialCustomThemes = await getTestimonialThemesMap(this.contentAsset.account.id)
      }
    },
    onDownload() {
      if (this.contentAsset.type === 'RenderableTestimonial') {
        this.downloadTestimonial()
      } else {
        this.downloadContentAsset()
      }
    },
    // Yikes there's a lot of tracking going on in these next methods!
    // The downloadTestimonial logic was copy/pasted from the previous
    // ShareTestimonialModalGuts.vue component. They haven not yet been
    // combined because the tracking params for testimonials are
    // slightly different than downloadContentAsset() below. These can
    // probably be consolidated a lot. But this shoudl be done carefully
    // in collaboration with stakeholders who are watching that data. So
    // leaving them as-is for now.
    downloadTestimonial() {
      ahoy.track('asset downloaded', this.eventData)

      amplitudeTrack('asset downloaded', {
        asset_id: this.contentAsset.identifier,
        asset_type: this.contentAsset.type,
        size: this.size,
        filetype: this.filetype,
        style: this.style,
        theme_id: this.active_theme_id,
        location: this.$route.path,
        account_id: this.contentAsset.account.id,
        account_name: this.contentAsset.account.name,
      })

      pendoTrack('download_asset', {
        asset_id: this.contentAsset.identifier,
        asset_type: 'RenderableTestimonial',
        size: this.size,
        filetype: this.filetype,
        style: this.style,
        theme_id: this.active_theme_id,
        location: this.$route.path,
      })

      this.download_in_progress = true
      if (this.filetype === 'pptx') {
        this.attemptPopUp(`/api/renderable_testimonials/${this.contentAsset.id}/download_pptx.json`)
        this.downloadInProgress = false
      } else {
        renderAsset(this.contentAsset.id, this.testimonialDownloadOptions)
          .then((r) => {
            return r.data.download_url
          })
          .then((url) => this.attemptPopUp(url))
          .catch(() => alert('Darn! Something went wrong. Please try again.'))
          .finally(() => (this.downloadInProgress = false))
      }
    },
    downloadContentAsset() {
      this.downloadInProgress = true
      downloadContentAsset(this.contentAsset.id, this.downloadOptions)
        .then((r: AxiosResponse) => {
          pendoTrack('download_asset', this.eventData)
          amplitudeTrack('asset downloaded', this.eventData)
          ahoy.track('asset downloaded', this.eventData)
          return r.data.download_url
        })
        .then((url: string) => this.attemptPopUp(url))
        .catch(() => {
          alert('Darn! Something went wrong. Please try again.')
        })
        .finally(() => (this.downloadInProgress = false))
    },
    trackCopy() {
      ahoy.track('asset share link copied', this.eventData)
    },
  },
}
</script>
<style lang="sass" scoped>
.modal_container
  font-size: 1em
  display: flex
  flex-flow: column
  height: 100%
  overflow-y: auto
  overflow-x: hidden
  &.modal_container .modal_header
    flex: 0 0 auto
    .title
      font-size: 1.5em
      font-weight: 700

  &.modal_container .modal_body
    min-height: 200px
    overflow-y: auto

  &.modal_container .asset_preview
    width: 60%
    height: 100%
  .sharing_options
    width: 40%
    gap: 10px
    .settings .header
      font-size: 1.25em
    .header
      font-size: 1em
      font-weight: 700

.modal_container--share_asset_modal_new
  &.modal_container .modal_footer_new
    border-top: 1px solid $border-color

.no-slide-preview-placeholder
  background: #EAEAEA
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  aspect-ratio: 16 / 9
  &.preview_slide
    background: transparent
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)


// This hack fixes an alignment issue with the radio buttons and their labels
::v-deep
  .custom-radio .custom-control-label::before,
  .custom-radio .custom-control-label::after
    top: 0.32rem!important

// This makes it easier to see the "Accent" testimonial against the white background
::v-deep
  .testimonial_social_191
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
</style>
